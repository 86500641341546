define("ember-cli-meta-tags/services/head-tags", ["exports", "@ember/object/internals", "@ember/service"], function (_exports, _internals, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const VALID_HEAD_TAGS = new Set(['base', 'link', 'meta', 'script', 'noscript', 'title']);
  let HeadTags = _exports.default = (_class = class HeadTags extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "headData", _descriptor, this);
    }
    // crawl up the active route stack and collect head tags
    collectHeadTags() {
      let tags = {};
      let currentHandlerInfos = this.router.targetState.routerJsState.routeInfos;
      currentHandlerInfos.forEach(handlerInfo => {
        Object.assign(tags, this._extractHeadTagsFromRoute(handlerInfo.route));
      });
      let tagArray = Object.keys(tags).map(id => tags[id]);
      this.headData.set('headTags', tagArray);
    }
    _extractHeadTagsFromRoute(route) {
      if (!route) {
        return {};
      }
      let headTags = route.headTags;
      if (!headTags) {
        return {};
      }
      if (typeof headTags === 'function') {
        headTags = headTags.apply(route);
      } else if (typeof headTags !== 'object') {
        // not recognized construct
        return {};
      }
      // convert headTags to object
      return this._buildTags(headTags);
    }

    // ensure all tags have a tagId and build object keyed by id
    _buildTags(headTagsArray) {
      let tagMap = {};
      headTagsArray.forEach(function (tagDefinition) {
        if (!tagDefinition || !VALID_HEAD_TAGS.has(tagDefinition.type)) {
          return;
        }
        let tagId = tagDefinition.tagId;
        if (!tagId) {
          tagId = (0, _internals.guidFor)(tagDefinition);
        }
        tagMap[tagId] = tagDefinition;
      });
      return tagMap;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "headData", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});
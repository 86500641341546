define("ember-svg-jar/inlined/uz-Cyrl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#0099B5\" d=\"M0 9v4h36V9a4 4 0 00-4-4H4a4 4 0 00-4 4z\"/><path fill=\"#1EB53A\" d=\"M36 27v-4H0v4a4 4 0 004 4h28a4 4 0 004-4z\"/><path fill=\"#CE1126\" d=\"M0 13h36v1.5H0zm0 8.5h36V23H0z\"/><path fill=\"#EEE\" d=\"M0 18v3.5h36v-7H0z\"/><path fill=\"#FFF\" d=\"M4.2 9.16a3.12 3.12 0 012.6-3.076 3.12 3.12 0 100 6.152A3.12 3.12 0 014.2 9.16zm4.683 2.303l-.14-.431-.14.431h-.454l.367.267-.14.431.367-.267.366.267-.14-.431.367-.267zm2.702 0l-.14-.431-.14.431h-.453l.367.267-.14.431.366-.267.367.267-.14-.431.367-.267zm-.14-2.927l-.14.431h-.453l.367.267-.14.431.366-.267.367.267-.14-.431.367-.267h-.454zm2.843 2.927l-.14-.431-.14.431h-.453l.366.267-.14.431.367-.267.367.267-.14-.431.366-.267zm-.14-2.927l-.14.431h-.453l.366.267-.14.431.367-.267.367.267-.14-.431.366-.267h-.453zm0-2.496l-.14.431h-.453l.366.267-.14.431.367-.267.367.267-.14-.431.366-.267h-.453zm2.843 5.423l-.14-.431-.14.431h-.454l.367.267-.14.431.367-.267.366.267-.14-.431.367-.267zm-.14-2.927l-.14.431h-.454l.367.267-.14.431.367-.267.366.267-.14-.431.367-.267h-.453zm0-2.496l-.14.431h-.454l.367.267-.14.431.367-.267.366.267-.14-.431.367-.267h-.453zm2.842 5.423l-.14-.431-.14.431h-.453l.367.267-.14.431.366-.267.367.267-.14-.431.367-.267zm-.14-2.927l-.14.431h-.453l.367.267-.14.431.366-.267.367.267-.14-.431.367-.267h-.454zm0-2.496l-.14.431h-.453l.367.267-.14.431.366-.267.367.267-.14-.431.367-.267h-.454z\"/>",
    "attrs": {
      "width": "800",
      "height": "800",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg",
      "aria-hidden": "true",
      "class": "iconify iconify--twemoji"
    }
  };
});